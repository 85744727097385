import './App.css';
import SeachWordle from './components/SearchWordle/SeachWordle';
import ErrorBoundary from './components/ErrorBoundary';


function App() {
  return (
    <ErrorBoundary>
      <SeachWordle />
    </ErrorBoundary>
  );
}

export default App;
