import React, { useCallback } from 'react';

const LetterInput = ({
  handleOnFocus,
  handleKeyDown,
  k,
  form,
  refInputs,
  setLock,
  locked
}) => {

  const toggleLock = useCallback((ev)=>{
    const index = +ev.target.name
    if(!!ev.target.value){
      setLock(data => ({...data, [index]: !data[index]}))
    }
  },[setLock])

  return (
    <input
      className={`${locked ? 'locked' : ''} ${!!form[k] ? 'pop' : ''}`}
      maxLength={1}
      onFocus={handleOnFocus}
      onKeyDown={handleKeyDown}
      onClick={toggleLock}
      type="text"
      name={k}
      key={k}
      value={form[k]}
      onChange={()=>{}}
      size="1"
      ref={el => (refInputs.current[k] = el)}
      autoComplete="off"
    />
  );
};

export default LetterInput;
