import React, { useEffect, useState } from 'react';
import solutions from '../../wordle_solutions.json';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const Solutions = ({ solutionsRef, toggleSolution }) => {
  const daysSinceAroma = Math.floor(
    Math.abs((new Date(2022, 1, 15) - Date.now()) / (1000 * 3600 * 24))
  );
  const indexOfAroma = solutions.indexOf('aroma');
  const todaysIndex = indexOfAroma + daysSinceAroma;
  const [yesterday, today, tomorrow] = solutions.slice(
    todaysIndex - 1,
    todaysIndex + 2
  );
  const [cont, setCont] = useState(false);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      solutionsRef.current.style.opacity = 1;
    }, 1000);
    return timeOut;
  }, [solutionsRef]);

  return (
    <div id="solutions" ref={solutionsRef}>
      {cont ? (
        <>
          <p>
            <b>Yesterday: </b>
            {yesterday}
          </p>
          <p>
            <b>Today: </b>
            {today}
          </p>
          <p>
            <b>Tomorrow: </b>
            {tomorrow}
          </p>
        </>
      ) : (
        <div>
          <div class="spoiler-alert">
            <h3>
              <FontAwesomeIcon
                icon={solid('circle-exclamation')}
                className="alert-icon"
              />
              <br />
              SPOILER
              <br />
              ALERT
            </h3>
            <p>Wordle solution ahead!!</p>
            <button onClick={() => setCont(true)}>
              <FontAwesomeIcon icon={solid('wand-magic-sparkles')} /> reveal
            </button>
          </div>
          <button className="heck-no" onClick={toggleSolution}>NOPE</button>
        </div>
      )}
    </div>
  );
};

export default Solutions;
