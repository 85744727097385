import React, { useCallback, useEffect } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';

const SearchResults = ({ results, headerHeight, lock }) => {
  const Row = ({ index, style, data }) => {
    const word = results[index];
    return (
      <p style={style} className="word-result">
        {word
          .split('')
          .map((c, i) =>
            data[i] ? <span className="char-lock">{c}</span> : c
          )}
      </p>
    );
  };
  return (
    <AutoSizer>
      {({ height, width }) => (
        <List
          height={headerHeight}
          itemCount={results.length}
          itemSize={35}
          width={width}
          itemData={lock}
        >
          {Row}
        </List>
      )}
    </AutoSizer>
  );
};

export default SearchResults;
