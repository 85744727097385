import React, { useEffect, useRef, useState, useCallback } from 'react';
import Key from './Key';
import useWindowSize from './useWindowSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

const alphabet = [...Array(26).keys()].map(code =>
  String.fromCharCode(97 + code)
);

const Keyboard = ({ setInvalidChars, invalidChars }) => {
  const [open, setOpen] = useState(true);
  const footer = useRef();
  const keyboard = useRef();
  const [width, height] = useWindowSize();

  const toggleKeyboard = useCallback((trigger=false)=>{
    if(trigger){
      footer.current.style.transform = `translateY(${0}px)`;
    }else{
      const footerBottom = footer.current.getBoundingClientRect().bottom
      const keyboardTop = keyboard.current.getBoundingClientRect().top
      const offsetY = keyboardTop - footerBottom;
      footer.current.style.transform = `translateY(${-offsetY}px)`;
    }
  },[])

  useEffect(() => {
    toggleKeyboard(open)
  }, [open, toggleKeyboard])

  useEffect(()=>{
    // Trigger render on window resize
    if(width || height){
      toggleKeyboard()
      setOpen(o => false)
    }
  }, [width, height, toggleKeyboard])

  return (
    <footer ref={footer} className={`open ? open : ""`}>
      <div className="keyboard-container">
        <h3 onClick={ev => setOpen(o => !o)} className={`disable-select ${invalidChars.length ? "active" : ''}`}>
      <FontAwesomeIcon icon={solid('keyboard')} className="keyboard-icon" /> &nbsp;
          <div className={`caret ${open ? 'rotate' : ''}`}>▲ </div>
           Exclude Letters <span>🔴</span>
        </h3>
        <div className="keyboard" ref={keyboard}>
          {alphabet.map(letter => (
            <Key key={letter} {...{ letter, setInvalidChars, invalidChars }} />
          ))}
          <div className="key clear" onClick={() => setInvalidChars([])}>
            ⌦
          </div>
        </div>
        <span className='disable-select credit' onClick={()=>{window.location="https://patricio.work/"}}>Design by Patricio</span>
      </div>
    </footer>
  );
};

export default Keyboard;
