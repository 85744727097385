import React, { useCallback, useEffect, useRef, useState } from 'react';
import useFormReducer from 'useformreducer';
import './SearchWordle.css';
import LetterInput from './LetterInput';
import data from '../../wordle_data.json';
import SearchResults from './SearchResults';
import Solutions from './Solutions';
import Keyboard from './Keyboard';
import Modal from './Modal';

const initForm = { 0: '', 1: '', 2: '', 3: '', 4: '' };

const SeachWordle = () => {
  const refInputs = useRef([]);
  const headerRef = useRef();
  const solutionsRef = useRef();
  const [form, setForm] = useFormReducer(null, initForm);
  const [lock, setLock] = useState(initForm);
  const [headerHeight, setHeaderHeight] = useState(100);
  const [results, setResults] = useState(['test']);
  const [invalidChars, setInvalidChars] = useState([]);

  useEffect(() => {
    // filer array by positional character
    const [aX, bX, cX, dX, eX] = Object.values(lock);
    const [a, b, c, d, e] = Object.values(form);
    const strictPattern = new RegExp(
      `^${!!aX ? a : '.'}${!!bX ? b : '.'}${!!cX ? c : '.'}${!!dX ? d : '.'}${
        !!eX ? e : '.'
      }$`,
      'i'
    );
    const filterStrictArray = w => strictPattern.exec(w);
    let filteredStrictArray = [aX, bX, cX, dX, eX].some(v => v)
      ? data.filter(filterStrictArray)
      : data;

    // filter by characters to Exclude
    const excludePattern = new RegExp(`[${invalidChars.join('')}]`);
    const excludeChars = char => !excludePattern.exec(char);
    const filteredExcludedCharsArray = invalidChars.length
      ? filteredStrictArray.filter(excludeChars)
      : filteredStrictArray;

    // further filtering by letters in word
    const pattern = new RegExp(
      `(?=.*${a})(?=.*${b})(?=.*${c})(?=.*${d})(?=.*${e})`,
      'i'
    );
    const filterArray = w => pattern.exec(w);
    setResults(filteredExcludedCharsArray.filter(filterArray));
  }, [form, lock, invalidChars]);

  const nextInput = useCallback((ev, direction = 1) => {
    const { current } = refInputs;
    const index = current.indexOf(ev.target);

    // CIRCULAR JUMPING INDEX
    // const newIndex = (index + direction) % current.length
    // current[newIndex < 0 ? current.length - 1 : newIndex].focus();

    // STOP AT 0 AND END OF LENGTH
    let newIndex = index + direction;
    if (newIndex < 0) {
      newIndex = 0;
    } else if (newIndex >= current.length) {
      newIndex = current.length - 1;
    }
    current[newIndex].focus();
  }, []);

  const handleKeyDown = useCallback(
    ev => {
      // ev.stopPropagation()
      const { keyCode } = ev;
      const formValue = refInputs.current.map(el => el.value);
      let index = +ev.target.name;
      if (keyCode === 8) {
        // delete
        if (formValue.every(v => v)) {
          // this handles cases when the ev is a syntheticEv
          // and all values are full
          index = formValue.lenth - 1;
        }
        if (!formValue[index] && index > 0) {
          const prevIndex = index - 1;
          const prevElem = refInputs.current[prevIndex];
          const syntheticEv = { keyCode, target: prevElem };
          handleKeyDown(syntheticEv);
          prevElem.focus();
        } else {
          ev.target.value = '';
          setForm(ev);
        }
        // setForm(ev);
        setLock(data => ({ ...data, [index]: false }));
      } else if (keyCode === 32) {
        // spacebar
        if (!!ev.target.value) {
          setLock(data => ({ ...data, [index]: !data[index] }));
        }
      } else if (keyCode >= 65 && keyCode <= 90) {
        // alpha chars
        const newChar = String.fromCharCode(keyCode);
        if ((!formValue[index] && !lock[index]) || lock[index]) {
          ev.target.value = newChar;
          setForm(ev);
        }
        if (!lock[index]) {
          nextInput({ target: ev.target }, 1);
        }
      } else if (keyCode === 37) {
        // left arrow
        nextInput({ target: ev.target }, -1);
      } else if (keyCode === 39) {
        // right arrow
        nextInput({ target: ev.target }, 1);
      }
    },
    [setForm, lock, nextInput]
  );

  const handleOnFocus = useCallback(ev => {
    ev.target.setSelectionRange(0, 0);
  }, []);

  // This is used to resize react-window height
  function handleWindowResize(event) {
    setHeaderHeight(
      (window.innerHeight - headerRef.current.clientHeight) * 0.9
    );
  }

  function getCurrentInput() {
    const { current } = refInputs;
    if (current.every(el => el.value)) return current[current.length - 1];
    else if (current.some(el => el.value))
      return current
        .filter(input => input.value !== '')
        .slice()
        .pop();
    return current.filter(input => input.value === '')[0];
  }

  const focusOnInputs = useCallback(
    ev => {
      const editingInputs = refInputs.current.includes(document.activeElement);
      if (!editingInputs) {
        const currentInput = getCurrentInput();
        const { keyCode } = ev;
        const syntheticEv = { keyCode, target: currentInput, synthetic: true };
        handleKeyDown(syntheticEv);
      }
    },
    [handleKeyDown]
  );

  const toggleSolution = useCallback(
    () => solutionsRef.current.classList.toggle('reveal'),
    []
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);
    handleWindowResize();
    // getCurrentInput().focus();
    window.addEventListener('keydown', focusOnInputs);
  }, []);
  return (
    <>
      <header ref={headerRef}>
        <Solutions solutionsRef={solutionsRef} toggleSolution={toggleSolution} />
        <h1 className="cristalball">
          <span onClick={toggleSolution}>🔮</span>
          type to search wordle solutions
        </h1>
        <div className="letters">
          {Object.keys(initForm).map(k => (
            <LetterInput
              key={k}
              {...{
                handleOnFocus,
                handleKeyDown,
                k,
                form,
                setForm,
                refInputs,
                setLock,
                locked: lock[k],
              }}
            />
          ))}
        </div>
        <h2>
          {results.length} solution{results.length !== 1 ? 's' : ''}
        </h2>
      </header>
      <SearchResults className="results" {...{ results, headerHeight, lock }} />
      <Modal />
      <Keyboard {...{ setInvalidChars, invalidChars }} />
    </>
  );
};

export default SeachWordle;
