import React from 'react';

const Key = ({ letter, setInvalidChars, invalidChars }) => {
  const handleClick = ev => {
    setInvalidChars(chars =>
      chars.includes(letter)
        ? chars.filter(l => l !== letter)
        : [...chars, letter]
    );
    const timer = setTimeout(() => {
      ev.target.classList.add('pop');
    }, 1);
    return () => clearTimeout(timer)
  };

  return (
    <div
      className={`key disable-select ${invalidChars.includes(letter) ? 'excluded' : ''}`}
      onClick={handleClick}
    >
      {letter}
    </div>
  );
};

export default Key;
