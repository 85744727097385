import React, { useEffect, useState } from 'react';
import Instructions from './Instructions';
import './Modal.css'

const Modal = () => {
  const [modal, setModal] = useState(false)

  useEffect(() => {
    const openModal = setTimeout(() => setModal(true), 2000)
    return () => clearTimeout(openModal)
  }, [])

  return (
  !modal ? <div className="disable-select question-icon" onClick={() => setModal(true)}>?</div> :
  <div className="overlay" style={{display: modal ? 'flex' : 'none'}}>
      <div className="content"> 
        <Instructions />
        <div className="disable-select close-icon" onClick={() => setModal(false)}>
          X
        </div>
      </div>
    </div>
  );
};

export default Modal;