import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro';

const GameTile = ({ letter, locked }) => {
  return <p className={`tile pop ${locked ? 'locked' : ''}`}>{letter}</p>;
};

const Instructions = () => {
  return (
    <section>
      <div className="instructions">
        <h4 className="instruction-title">
          <strong>WORDLE</strong> SEARCH <span>| word finder</span>
        </h4>
        <hr />
        <h2>
          <li>Filter words by any letter</li>
          <li>Lock letters in position</li>
          <li>Exclude letters</li>
        </h2>

        <div className="examples">
          <div className="example">
            <p>
              <strong>Filter</strong> <br />
              To filter words just type
            </p>
            <div className="row">
              <GameTile letter="s"></GameTile>
              <GameTile letter="m"></GameTile>
              <GameTile letter="o"></GameTile>
              <GameTile letter="r"></GameTile>
              <GameTile letter="e"></GameTile>
            </div>
            <p className="result-words">
              <b>Result:</b> moers, mores, morse, omers and smore.
            </p>
          </div>
          <div className="example">
            <p>
              <strong>Lock</strong> <br />
              To lock a letter tap <span className="mouse">&#9755;</span> on it or
              press <kbd>space</kbd>
            </p>
            <div className="row">
              <GameTile letter="s"></GameTile>
              <GameTile letter="m" locked={true}></GameTile>
              <GameTile letter="o"></GameTile>
              <GameTile letter="r"></GameTile>
              <GameTile letter="e"></GameTile>
            </div>
            <p className="result-words">
              <b>Result:</b> o<b style={{ color: '#538d4e' }}>m</b>ers and s
              <b style={{ color: '#538d4e' }}>m</b>ore
            </p>
          </div>
          <div className="example">
            <p>
              <strong>Exclude</strong> <br />
              Open Exclude Letters &nbsp; <FontAwesomeIcon icon={solid('keyboard')} /> &nbsp; and tap{' '}
              <span className="key excluded pop">o</span>{' '}
              <span className="mouse">&#9755;</span>
            </p>
            <div className="row">
              <GameTile letter="s"></GameTile>
              <GameTile letter="m" locked={true}></GameTile>
              <GameTile letter="o"></GameTile>
              <GameTile letter="r"></GameTile>
              <GameTile letter="e"></GameTile>
            </div>

            <p className="result-words">
              <b>Result:</b> Every word contained an "o". Result Empty.
            </p>
          </div>
        </div>
      </div>
      <hr />
      <p>
              Keyboard navigation <kbd>&#8592;</kbd> or <kbd>&#8594;</kbd>
            </p>
    </section>
  );
};

export default Instructions;
